import type { TranslationInterface } from './translation.types';

export const arLocale: TranslationInterface = {
  ok: 'حسنا',
  yes: 'نعم',
  no: 'لا',
  agree: 'موافق',
  cancel: 'إلغاء',
  'yes-exit': 'نعم، اخرج',
  'yes-reset': 'نعم، إعادة تعيين',
  'no-cancel': 'لا، إلغاء',
  'are-you-sure': 'هل أنت متأكد؟',
  recording: 'تسجيل',
  'thank-you': 'شكرا',
  'sorry-try-again': 'عذرا، حاول مرة أخرى',
  'error-occurred': 'حدث خطأ',
  'please-try-again': 'يرجى المحاولة مرة أخرى',
  'write-a-message': 'اكتب رسالة...',
  'send-message': 'إرسال رسالة',
  connected: 'متصل',
  connecting: 'جاري الاتصال',
  reconnecting: 'جاري إعادة الاتصال',
  reconnected: 'تم إعادة الاتصال',
  disconnecting: 'جاري قطع الاتصال',
  disconnected: 'قطع الاتصال',
  error: 'خطأ',
  'persist-session': 'حفظ الجلسة',
  settings: 'إعدادات',
  close: 'إغلاق',
  help: 'مساعدة',
  chat: 'محادثة',
  send: 'إرسال',
  copy: 'نسخ',
  copied: 'تم النسخ',
  'sound-effects': 'التاثيرات الصوتية',
  language: 'اللغة',
  select: 'اختر',
  agent: 'الوكيل',
  user: 'المستخدم',
  bot: 'البوت',
  'close-widget': 'إغلاق الودجة',
  'got-any-questions': 'هل لديك أي أسئلة؟ تحدث معنا!',
  'typical-response-time': 'عادة ما نرد في أقل من دقيقة واحدة',
  'session-closed-lead': 'تم حل مشكلتك!',
  exit: 'خروج',
  'create-new-ticket': 'إنشاء تذكرة جديدة',
  'reset-conversation': 'إعادة تعيين المحادثة',
  'new-conversation': 'محادثة جديدة',
  'welcome-title': 'مرحبًا بك في دردشة الدعم الخاصة بنا',
  'welcome-description':
    'نحن هنا للمساعدة! ابدأ محادثة وسنرد عليك في أقرب وقت ممكن.',
  'your-name': 'اسمك',
  'your-email': 'عنوان بريدك الإلكتروني',
  'start-chat': 'تحدث إلى الدعم',
  'starting-chat': 'جاري الاتصال...',
  'hello-greeting': 'مرحبًا! كيف يمكننا مساعدتك اليوم؟',
  'i-need-more-help': 'أحتاج المزيد من المساعدة',
  'this-was-helpful': 'كان هذا مفيدًا',
  optional: 'اختياري',
  'no-conversations-yet': 'لا يوجد محادثات',
};
