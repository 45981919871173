import type { TranslationInterface } from './translation.types';

export const enLocale: TranslationInterface = {
  ok: 'OK',
  yes: 'Yes',
  no: 'No',
  agree: 'Agree',
  cancel: 'Cancel',
  'yes-exit': 'Yes, exit',
  'yes-reset': 'Yes, reset',
  'no-cancel': 'No, cancel',
  'are-you-sure': 'Are you sure?',
  recording: 'Recording...',
  'thank-you': 'Thank you',
  'sorry-try-again': 'Sorry, please try again',
  'error-occurred': 'An error occurred',
  'please-try-again': 'Please try again',
  'write-a-message': 'Write a message...',
  'send-message': 'Send message',
  connected: 'Connected',
  connecting: 'Connecting',
  reconnecting: 'Reconnecting',
  reconnected: 'Reconnected',
  disconnecting: 'Disconnecting',
  disconnected: 'Disconnected',
  error: 'Error',
  'persist-session': 'Persist session',
  settings: 'Settings',
  close: 'Close',
  help: 'Help',
  chat: 'Chat',
  send: 'Send',
  copy: 'Copy',
  copied: 'Copied',
  'sound-effects': 'Sound effects',
  language: 'Language',
  select: 'Select',
  agent: 'Agent',
  user: 'User',
  bot: 'Bot',
  'close-widget': 'Close widget',
  'got-any-questions': 'Got any questions? Chat with us!',
  'typical-response-time': 'Typically respond in less than 1 minute',
  'session-closed-lead': 'Your issue has been resolved!',
  'create-new-ticket': 'Create new ticket',
  exit: 'Exit',
  'reset-conversation': 'Reset conversation',
  'new-conversation': 'New conversation',
  'welcome-title': 'Welcome to our support chat',
  'welcome-description':
    "We're here to help! Start a conversation and we'll get back to you as soon as possible.",
  'your-name': 'Your name',
  'your-email': 'Your email address',
  'start-chat': 'Talk to support',
  'starting-chat': 'Connecting...',
  'hello-greeting': 'Hi there!',
  'i-need-more-help': 'I need more help',
  'this-was-helpful': 'This was helpful',
  optional: 'Optional',
  'no-conversations-yet': 'No conversations yet',
};
