import type { TranslationInterface } from './translation.types';

export const deLocale: TranslationInterface = {
  ok: 'OK',
  yes: 'Ja',
  no: 'Nein',
  agree: 'Zustimmen',
  cancel: 'Stornieren',
  'yes-exit': 'Ja, beenden',
  'yes-reset': 'Ja, zurücksetzen',
  'no-cancel': 'Nein, abbrechen',
  'are-you-sure': 'Sind Sie sicher?',
  recording: 'Aufnahme läuft',
  'thank-you': 'Vielen Dank',
  'sorry-try-again': 'Entschuldigung, bitte versuchen Sie es erneut',
  'error-occurred': 'Ein Fehler ist aufgetreten',
  'please-try-again': 'Bitte versuchen Sie es erneut',
  'write-a-message': 'Nachricht schreiben...',
  'send-message': 'Nachricht senden',
  connected: 'Verbunden',
  connecting: 'Verbindung wird hergestellt',
  reconnecting: 'Verbindung wird wiederhergestellt',
  reconnected: 'Wieder verbunden',
  disconnecting: 'Verbindung wird getrennt',
  disconnected: 'Verbindung getrennt',
  error: 'Fehler',
  'persist-session': 'Sitzung beibehalten',
  settings: 'Einstellungen',
  close: 'Schließen',
  help: 'Hilfe',
  chat: 'Chat',
  send: 'Senden',
  copy: 'Kopieren',
  copied: 'Kopiert',
  'sound-effects': 'Soundeffekte',
  language: 'Sprache',
  select: 'Auswählen',
  agent: 'Agent',
  user: 'Benutzer',
  bot: 'Bot',
  'got-any-questions': 'Haben Sie Fragen? Chatten Sie mit uns!',
  'typical-response-time':
    'Üblicherweise antworten wir in weniger als 1 Minute',
  'close-widget': 'Widget schließen',
  'session-closed-lead': 'Ihr Problem wurde gelöst!',
  'create-new-ticket': 'Neues Ticket erstellen',
  exit: 'Beenden',
  'reset-conversation': 'Konversation zurücksetzen',
  'new-conversation': 'Neue Konversation',
  'welcome-title': 'Willkommen in unserem Support-Chat',
  'welcome-description':
    'Wir sind hier, um zu helfen! Beginnen Sie ein Gesprách und wir werden so schnell wie mogelijk antworten.',
  'your-name': 'Ihr Name',
  'your-email': 'Ihre E-Mail-Adresse',
  'start-chat': 'Mit dem Support sprechen',
  'starting-chat': 'Verbindung wird hergestellt...',
  'hello-greeting': 'Hallo! Wie können wir Ihnen heute helfen?',
  'i-need-more-help': 'Ich brauche weitere Hilfe',
  'this-was-helpful': 'Dies war hilfreich',
  optional: 'Optional',
  'no-conversations-yet': 'noch keine Gespräche',
};
