import React from 'react';
import { cn } from '../lib/utils/cn';

export function OpenLogoSvg({ className }: { className?: string }) {
  return (
    <svg
      width="26"
      height="32"
      viewBox="0 0 26 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('text-primary-foreground', className)}
    >
      <title>Open Logo</title>
      <path
        d="M1.34103 25.6132H5.44245C5.74203 25.6228 6.02673 25.7462 6.23867 25.9582C6.45062 26.1703 6.5739 26.4551 6.58348 26.7548V30.8584C6.59305 31.1581 6.71634 31.443 6.92828 31.655C7.14023 31.8671 7.42492 31.9904 7.7245 32H18.2894C18.589 31.9904 18.8737 31.8671 19.0856 31.655C19.2976 31.443 19.4209 31.1581 19.4304 30.8584V26.7548C19.4401 26.4545 19.5638 26.1692 19.7765 25.957C19.9892 25.7449 20.2748 25.6219 20.5749 25.6132H24.659C24.9586 25.6036 25.2433 25.4803 25.4552 25.2682C25.6671 25.0562 25.7904 24.7713 25.8 24.4716V7.52839C25.7904 7.22865 25.6671 6.94381 25.4552 6.73176C25.2433 6.5197 24.9586 6.39635 24.659 6.38677H20.561C20.2603 6.37802 19.9742 6.25457 19.7614 6.04169C19.5487 5.82881 19.4253 5.54261 19.4165 5.24168V1.14161C19.4072 0.84419 19.2858 0.561291 19.0768 0.349589C18.8678 0.137887 18.5865 0.0130193 18.2894 0L7.7245 0C7.42492 0.00957792 7.14023 0.132929 6.92828 0.344983C6.71634 0.557036 6.59305 0.841878 6.58348 1.14161V5.24168C6.57477 5.54203 6.45187 5.82773 6.23983 6.04052C6.0278 6.25331 5.74262 6.37715 5.44245 6.38677H1.34103C1.04144 6.39635 0.756749 6.5197 0.544805 6.73176C0.332861 6.94381 0.209574 7.22865 0.200001 7.52839V24.4716C0.209574 24.7713 0.332861 25.0562 0.544805 25.2682C0.756749 25.4803 1.04144 25.6036 1.34103 25.6132ZM6.58348 24.4716V7.52839C6.59305 7.22865 6.71634 6.94381 6.92828 6.73176C7.14023 6.5197 7.42492 6.39635 7.7245 6.38677H18.2894C18.589 6.39635 18.8737 6.5197 19.0856 6.73176C19.2976 6.94381 19.4209 7.22865 19.4304 7.52839V24.4716C19.4209 24.7713 19.2976 25.0562 19.0856 25.2682C18.8737 25.4803 18.589 25.6036 18.2894 25.6132H7.7245C7.42492 25.6036 7.14023 25.4803 6.92828 25.2682C6.71634 25.0562 6.59305 24.7713 6.58348 24.4716Z"
        fill="currentColor"
      />
    </svg>
  );
}
