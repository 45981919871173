import type { TranslationInterface } from './translation.types';

export const frLocale: TranslationInterface = {
  ok: "D'accord",
  yes: 'Oui',
  no: 'Non',
  agree: 'Accepter',
  cancel: 'Annuler',
  'yes-exit': 'Oui, quitter',
  'yes-reset': 'Oui, réinitialiser',
  'no-cancel': 'Non, annuler',
  'are-you-sure': 'Êtes-vous sûr ?',
  recording: 'Enregistrement...',
  'thank-you': 'Merci',
  'sorry-try-again': 'Désolé, veuillez réessayer',
  'error-occurred': "Une erreur s'est produite",
  'please-try-again': 'Veuillez réessayer',
  'write-a-message': 'Écrivez un message...',
  'send-message': 'Envoyer le message',
  connected: 'Connecté',
  connecting: 'Connexion en cours',
  reconnecting: 'Reconnexion en cours',
  reconnected: 'Reconnecté',
  disconnecting: 'Déconnexion en cours',
  disconnected: 'Déconnecté',
  error: 'Erreur',
  'persist-session': 'Conserver la session',
  settings: 'Paramètres',
  close: 'Fermer',
  help: 'Aide',
  chat: 'Chat',
  send: 'Envoyer',
  copy: 'Copier',
  copied: 'Copié',
  'sound-effects': 'Effets sonores',
  language: 'Langue',
  select: 'Sélectionner',
  agent: 'Agent',
  user: 'Utilisateur',
  bot: 'Bot',
  'close-widget': 'Fermer le widget',
  'got-any-questions': 'Vous avez des questions ? Chattez avec nous !',
  'typical-response-time': "Nous répondons généralement en moins d'une minute",
  'session-closed-lead': 'Votre problème a été résolu !',
  'create-new-ticket': 'Créer un nouveau ticket',
  exit: 'Sortie',
  'reset-conversation': 'Réinitialiser la conversation',
  'new-conversation': 'Nouvelle conversation',
  'welcome-title': 'Bienvenue dans notre chat de support',
  'welcome-description':
    'Nous sommes là pour vous aider ! Commencez une conversation et nous vous répondrons dès que possible.',
  'your-name': 'Votre nom',
  'your-email': 'Votre adresse e-mail',
  'start-chat': 'Parler au support',
  'starting-chat': 'Connexion...',
  'hello-greeting': "Bonjour ! Comment pouvons-nous vous aider aujourd'hui ?",
  'i-need-more-help': "Je besoin d'aide plus",
  'this-was-helpful': "C'était utile",
  optional: 'Optionnel',
  'no-conversations-yet': 'Aucune conversation pour le moment',
};
